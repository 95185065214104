.disabledClass 
{
    opacity: 0.5;
    cursor: not-allowed;
}
.landing__da{
    .navbar, .footer, .moblie_footer{
        display: none;
    }
}
.da-btn-orange-gradient{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
    border-radius: 50px;
    background: linear-gradient(177deg, #F36A24 17.87%, #8C3305 101.33%);
    text-decoration: none;
    padding: 16.5px 72px;
    cursor: pointer;
    display: block;
    max-width: 278px;
    margin: 0 auto;
    &:hover{
        background: linear-gradient(177deg, #8C3305 17.87%, #F36A24 101.33%);
    }
}
:root {
	--bg: rgb(9, 14, 23);
	--gradientBg: radial-gradient(77.56% 98.56% at 3.93% 26.35%, #FFB36D 0%, #F36A24 89.06%, #8F3203 100%); 
}
.wrapper {
	position: relative;
}
/* Text */
.hero__heading {
	font-size: clamp(2rem, 5vw, 8rem);
	text-transform: uppercase;
	margin: 0;
}

/* Hero */
.hero {
	min-height: 100vh;
	padding: clamp(1rem, 2vw, 5rem);
	display: flex;
	align-items: center;
}

.hero--secondary {
	--mask: radial-gradient(
		circle at var(--x, 50%) var(--y, 50%),
    black var(--maskSize1, 0), 
		transparent 0, 
		transparent var(--maskSize2, 0),
    black var(--maskSize2, 0), 
		black var(--maskSize3, 0), 
		transparent 0);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--gradientBg);
	color: #fff !important;
	-webkit-mask-image: var(--mask);
	mask-image: var(--mask);
}

.pull-it-up{
    transform: translateY(-10px);
}
.text-right{
    text-align: right;
}
.orange-gradient-text {
    background-image: linear-gradient(177deg, #F36A24 17.87%, #8C3305 101.33%);
    -webkit-background-clip: text; /* For older browsers */
    background-clip: text;
    color: transparent; /* Hide original text */
}