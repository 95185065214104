@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "Hey November";
  src: url('./fonts/Hey\ November.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
:root {
    --gradient: linear-gradient(180deg, rgb(162, 19, 19) 0%, rgba(254, 254, 254, 1) 25%, rgba(0, 0, 0, 1) 25%, rgba(3, 3, 3, 1) 100%);
  }
  
*{
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: 'Poppins', sans-serif;

}
body{
    background: #181D26;
   /* -webkit-transform:translate3d(0,0,0); */
   
}

#whats_right_wrapperId{
  scroll-margin-block-start: 100px;
}
/* #root{
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  max-height: 100vh;
  scroll-padding-top: 75px;
} */


/* .snapScroll{
  scroll-behavior: smooth;
  scroll-snap-align: start;
} */