.nav{
    .desktopLogo{
        display: none;
        @media only screen and (min-width: 768px)
        {
            display: block;
        }
    }
    .mobileLogo{
        display: block;
        @media only screen and (min-width: 768px)
        {
            display: none;
        }
    }
}

.navbar {
    background: rgba(39, 48, 63, 0.24);
    backdrop-filter: blur(22.5px);
    width: 100%;
    transition: background 1s ease;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  
    &.dark {
      background: rgba(39, 48, 63, 0.65);
      backdrop-filter: blur(22.5px);
    }
  
    .nav {
      max-width: 1539px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 7.5px;
      padding-bottom: 7.5px;
      padding-left: 20px;
      padding-right: 20px;
      @media only screen and (min-width: 1440px) and (max-width: 1550px){
        max-width: 1422px;
      }
  
      @media screen and (max-width:768px) {
        padding-left: 10px;
        padding-right: 10px;
      }
  
      .mobileLogo {
        margin-bottom: 10px;
  
      }
  
      ul {
        display: flex;
        list-style: none;
        gap: 25px;
  
        li {
          span {
            margin-left: 10px;
          }
  
          .menu-text {
            @media screen and (max-width:767px) {
              display: none;
            }
  
          }
  
          .span {
            border: 1px solid #E37E49;
  
            border-radius: 50%;
            padding: 10px;
  
            @media screen and (max-width:575px) {
              padding: 9px;
            }
          }
  
          a {
            color: #FFF;
            text-decoration: none;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.72px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .overlay {
    position: fixed;
    left: 0;
    top: -100vh;
    width: 100%;
    height: calc(100vh - 75px);
    // min-height: 100%;
    transition: top 1s ease;
    // background-image: url('./Rectangle\ 2048.png');
    background-position: center;
    background-size: cover;
    // background: rgba(39, 48, 63, 0.65);
    backdrop-filter: blur(40.5px);
    z-index: 9989;
    justify-content: center;
    align-items: center;
  
    @media screen and (max-width:1600px) {
      overflow-y: scroll;
      height: calc(100vh - 70.25px);
      min-height: 0;
  
    }
  
    @media screen and (max-width:992px) {
      overflow-y: hidden;
      height: calc(100vh - 70.25px);
      min-height: 100%;
    
  
    }
  
    &.slideIn {
      top: 75px;
  
      @media screen and (max-width:575px) {
        top: 13px;
      }
    }
  
    &.slideOut {
      top: -110%;
    }
  
    .centerText {
      position: absolute;
      max-width: 500px;
      max-height: 500px;
      overflow: hidden;
      z-index: 1;
      @media screen and (max-width:1220px) {
        display: none;
      }
  
      .subMenuItems {
        // overflow: hidden;
        opacity: 0;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 0px;
        transition: height 1s ease, opacity 1s ease;
  
  
  
        a {
          color: #fff;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.56px;
          cursor: pointer;
          text-decoration: none;
          margin-bottom: 10px;
          background-color: transparent;
          max-width: 300px;
          width: 100%;
          text-align: center;
          padding: 11px 0;
          border-radius: 28px;
          border: 1px solid #fff9;
          margin-bottom: 15px;
          transition: 0.3s ease;
  
          &:hover {
            background: linear-gradient(180deg, #F36A24 0%, #C74300 100%);
  
  
            border: 1px solid transparent;
          }
  
        }
  
        .submenu-active {
          background: linear-gradient(180deg, #F36A24 0%, #C74300 100%);
          border: 1px solid transparent;
  
  
        }
  
  
        &.show {
          height: 500px;
          opacity: 1;
        }
  
      }
  
      h1 {
        color: #FFF;
        text-align: center;
        font-family: 'Impact', sans-serif;
        font-size: clamp(32px, 5.5vw, 50px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.021px;
      }
      h2{
        margin-bottom: 0;
        text-align: center;
        font-family: Poppins;
        font-size: clamp(48px, 5.5vw, 96px);
        // font-size: 96px;
        font-style: normal;
        font-weight: 700;
        line-height: 118px; /* 85.417% */
        letter-spacing: -7.68px;
        background: linear-gradient(160deg, #FFF 48.49%, rgba(255, 255, 255, 0.00) 98.8%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;     
        @media only screen and (min-width: 1440px) and (max-width: 1550px){
          font-size: 72px;
        }       
      }
      .subHereLine{
        margin-bottom: 0;
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 275;
        line-height: normal;
        text-transform: uppercase;     
        @media only screen and (min-width: 1199px) and (max-width: 1550px){
          font-size: 18px;
        }   
      }
  
      .hereLine {
        color: #FFF;
        font-family: 'HeyNovember', sans-serif;
        font-size: clamp(48px, 5.5vw, 72px);
        font-style: normal;
        font-weight: 400;
        line-height: 90.5%;
        letter-spacing: 1.705px;
        margin: 0;
      }
  
      span {
        filter: blur(3px);
        -webkit-filter: blur(3px);
        font-family: 'HeyNovember', sans-serif;
      }
    }
  
  
    .mobileMenuDiv {
      position: relative;
      left: -150px;
      max-height: 580px;
      overflow: hidden;
      width: 100vw;
      z-index: 1;
      @media screen and (max-width:767px) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
  
      }
  
      @media screen and (max-width:767px) {
        width: 100vw;
        left: 0px;
      }
  
      // @media screen and (max-width:720px) {
      //   width: 110vw;
      //   left: -70px;
      // }
  
      // @media screen and (max-width:600px) {
      //   left: 0px;
      //   width: 95vw;
      // }
  
      &::before {
        content: '';
        position: absolute;
        width: 480px;
        height: 480px;
        top: 50%;
        left: -200px;
        border: 2px solid #F36A24;
        border-radius: 50%;
        transform: translateY(-50%);
  
        @media screen and (max-width:767px) {
          display: none;
        }
  
        // @media screen and (max-width:720px) {
        //   left: -270px;
        // }
  
        // @media screen and (max-width:600px) {
        //   left: -340px;
        // }
  
        // @media screen and (max-width:600px) {
        //   left: -400px;
        // }
  
        // @media screen and (max-width:425px) {
        //   display: none;
        // }
  
        @media screen and (min-width:768px) {
          width: 580px;
          height: 580px;
          left: -230px;
        }
      }
  
      @media screen and (min-width:768px) {
        left: -50px;
        max-height: 680px;
        overflow: hidden;
        width: 100vw;
      }
  
      @media screen and (min-width:1221px) {
        display: none;
      }
  
      .mobileMenu {
        float: left;
        width: 500px;
        height: 500px;
        margin: 20px;
        shape-outside: circle(50% at 15%);
        clipPath: circle(50% at 0%);
  
        // @media screen and (min-width:590px) and (max-width:767px) {
        @media screen and (max-width:767px) {
          // shape-outside: circle(45% at 1%);
            display: none;
        }
  
        // @media screen and (max-width:600px) {
        //   shape-outside: circle(45% at -15%)
        // }
  
        // @media screen and (max-width:450px) {
        //   shape-outside: circle(45% at -25%)
        // }
  
        @media screen and (max-width:425px) {
          display: none;
        }
  
        @media screen and (min-width:768px) {
          width: 580px;
          height: 580px;
          margin: 0px;
        }
  
  
      }
  
      .mobileMenuItems {
        transition: width 1s ease, height 1s ease;
        position: relative;
        z-index: 1;
  
        .menuItems {
          color: #FFF;
          font-family: Poppins;
          font-size: 25px;
  
          min-height: 40px;
          padding: 10px 0;
          a{
            color: inherit;
            text-decoration: none;
          }
          @media screen and (max-width:575px) {
            font-size: 15px;
          }
  
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-decoration: none;
          transition: background 1s ease;
  
          &:hover {
            svg {
              background: #F36A24;
              border-bottom: 0.89px solid #F36A24;
            }
          }
  
          svg {
            margin: 0px 15px;
            background: #161B23;
            border-radius: 50%;
            border-bottom: 0.89px solid #F36A24;
            min-width: 49px;
            min-height: 49px;
            padding: 15px;
          }
        }
  
        &.hide {
          height: 0px;
          width: 0px;
          overflow: hidden;
        }
      }
  
      .mobileSubMenuItems {
        display: none;
  
  
        &.show {
          display: initial;
          position: relative;
          z-index: 1;
          @media screen and (max-width:767px) {
            width: calc(100vw - 35%);
            transform: translateX(5%);
            br{
              display: none;
            }
          }
          @media screen and (max-width:575px) {
            width: 95vw;
            transform: translateX(0%);
            .menuItemBack{
              margin-left: 0px;
            }
            .menuItem{
              display: flex;
              align-items: center;
              svg{
                margin-left: 15px;
              }
              margin-top: 30px;
              margin-bottom: 30px;
              
            }
          }
          @media screen and (max-width:430px) {
            width: 80%;
            transform: translateX(0%);
            .menuItem{
              svg{
                margin-left: 0px;
              } 
            }
          }
        }
  
        .menuItemBack {
          position: absolute;
          top: 0;
          left: 45px;
          @media screen and (max-width:767px) {
            position: relative;
            margin-left: -85px;
            left: 0;
            display: block;
            margin-bottom: 15px;
          }
          @media screen and (max-width:430px) {
            margin: 0;
            margin-bottom: 15px;
          }
  
          svg {
            margin: 0px 15px;
            background: #F36A24;
            border-radius: 50%;
            border: 0.89px solid #F36A24;
            min-width: 55px;
            min-height: 55px;
            padding: 15px;
            @media screen and (max-width:430px) {
              margin: 0;
            }
          }
        }
  
        .menuItem {
          color: #FFF;
          font-family: Poppins;
          font-size: 25px;
  
          @media screen and (max-width:575px) {
            font-size: 18px;
          }
  
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-decoration: none;
  
          svg {
            margin: 0px 15px;
            background: transparent;
            border-radius: 50%;
            border: 0.89px solid #F36A24;
            min-width: 55px;
            min-height: 55px;
            padding: 15px;
            @media screen and (max-width:767px) {
              margin-left: -70px;
            }
            
          }
        }
  
        .subMenuItems {
          color: #FFF;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          padding: 10px 0;
          letter-spacing: 0.56px;
          cursor: pointer;
          text-decoration: none;
          margin-bottom: 15px;
          text-decoration: none; 
          
          text-align: center;
          border-radius: 30px;
          
          padding: 9px 14px;
          border: 1px solid white;
          // background: #C74300;
          @media screen and (max-width:767px) {
            display: block;
            line-height: 28px;
          }
          // a{
          //   max-width: 200px ;
          //   width: 100%;
          // }
          // @media screen and (max-width:450px) {
          //   padding-left: 20px;
          // }
        }
      }
    }
  
    .outerCircle {
      position: relative;
      z-index: 1;
      @media screen and (max-width:1220px) {
        display: none;
      }
  
      .menuItems {
        display: flex;
        align-items: center;
  
        &:hover {
          .menuItemIcon {
            background: #F36A24;
          }
        }
  
        cursor: pointer;
  
        .MenuHeading {
          color: #FFF;
          font-family: Poppins;
          font-size: clamp(14px, 5.5vw, 16px);
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.96px;
          text-transform: uppercase;
        }
  
        .menuItemIcon {
          background: #161B23;
          border-radius: 50%;
          border-bottom: 0.89px solid #F36A24;
          margin: 10px;
          min-width: 73px;
          min-height: 73px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background 0.4s ease;
        }
      }
  
      .subMenu {
  
        // display: none;
        ul {
          list-style: none;
          text-align: start;
        }
      }
  
      .topLeft {
        position: absolute;
        bottom: 0;
        right: 0;
        border-top: 1px solid;
        border-left: 1px solid;
        border-color: #F36A24;
        background: transparent;
        width: 300px;
        height: 300px;
        transition: width 0.3s, height 0.3s, transform 0.3s;
        transform-origin: bottom left;
        border-top-left-radius: 100%;
  
        @media only screen and (min-width: 1199px) and (max-width: 1550px){
          width: 250px;
          height: 250px;
        }
        @media screen and (min-width:1699px) {
          width: 311px;
          height: 311px;
        }
  
        .subMenuDot1 {
          top: 3%;
          right: 33%;
  
          .menuItems {
            position: relative;
            min-width: max-content;
            bottom: 80px;
            right: 135px;
  
            .subMenu {
              li {
                position: relative;
  
                &:first-child {
                  left: -25%;
                }
  
                &:nth-child(2) {
                  left: -50%;
                }
  
                &:nth-child(3) {
                  left: -75%;
                }
              }
            }
          }
        }
  
        .subMenuDot2 {
          bottom: 33%;
          left: 3%;
  
          .menuItems {
            position: relative;
            min-width: max-content;
            bottom: 40px;
            left: -190px;
  
            .subMenu {
              li {
                position: relative;
  
                &:first-child {
                  left: -25%;
                }
  
                &:nth-child(2) {
                  left: -50%;
                }
  
                &:nth-child(3) {
                  left: -75%;
                }
              }
            }
          }
        }
      }
  
      .topRight {
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid;
        border-right: 1px solid;
        border-color: #F36A24;
        background: transparent;
        width: 300px;
        height: 300px;
        transition: width 0.3s, height 0.3s, transform 0.3s;
        transform-origin: bottom right;
        border-top-right-radius: 100%;
        @media only screen and (min-width: 1199px) and (max-width: 1550px){
          width: 250px;
          height: 250px;
        }
        @media screen and (min-width:1699px) {
          width: 311px;
          height: 311px;
        }
        .subMenuDot1 {
          top: 25%;
          left: 67%;
  
          .menuItems {
            position: relative;
            min-width: max-content;
            bottom: 85px;
            left: calc(100% - 28px);
  
            .subMenu {
              li {
                position: relative;
  
                &:first-child {
                  right: -25%;
                }
  
                &:nth-child(2) {
                  right: -50%;
                }
  
                &:nth-child(3) {
                  right: -75%;
                }
              }
            }
          }
        }
  
        .subMenuDot2 {
          bottom: calc(0% - 11px);
          right: calc(0% - 11px);
  
          .menuItems {
            position: relative;
            min-width: max-content;
            bottom: 50px;
            left: calc(100% + 0px);
  
            .subMenu {
              li {
                position: relative;
  
                &:first-child {
                  right: -40%;
                }
  
                &:nth-child(2) {
                  right: -50%;
                }
  
                &:nth-child(3) {
                  right: -40%;
                }
              }
            }
          }
        }
      }
  
      .bottomLeft {
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        border-left: 1px solid;
        border-bottom: 1px solid;
        border-color: #F36A24;
        width: 300px;
        height: 300px;
        transition: width 0.3s, height 0.3s, transform 0.3s;
        transform-origin: top left;
        border-bottom-left-radius: 100%;
        @media only screen and (min-width: 1199px) and (max-width: 1550px){
          width: 250px;
          height: 250px;
        }
        @media screen and (min-width:1699px) {
          width: 311px;
          height: 311px;
        }
        .subMenuDot1 {
          left: 3%;
          top: 33%;
  
          .menuItems {
            position: relative;
            min-width: max-content;
            bottom: 0px;
            left: -160px;
  
            .subMenu {
              li {
                position: relative;
  
                &:first-child {
                  left: -75%;
                }
  
                &:nth-child(2) {
                  left: -50%;
                }
  
                &:nth-child(3) {
                  left: -25%;
                }
              }
            }
          }
        }
  
        .subMenuDot2 {
          bottom: 3%;
          right: 33%;
  
          .menuItems {
            position: relative;
            min-width: max-content;
            bottom: -5px;
            left: -215px;
  
            .subMenu {
              li {
                position: relative;
  
                &:first-child {
                  left: -75%;
                }
  
                &:nth-child(2) {
                  left: -50%;
                }
  
                &:nth-child(3) {
                  left: -25%;
                }
              }
            }
          }
        }
      }
  
      .bottomRight {
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: #F36A24;
        width: 300px;
        height: 300px;
        transition: width 0.3s, height 0.3s, transform 0.3s;
        transform-origin: top right;
        border-bottom-right-radius: 100%;
        @media only screen and (min-width: 1199px) and (max-width: 1550px){
          width: 250px;
          height: 250px;
        }
        @media screen and (min-width:1699px) {
          width: 311px;
          height: 311px;
        }
  
        .subMenuDot1 {
          bottom: 25%;
          left: 67%;
  
          .menuItems {
            position: relative;
            min-width: max-content;
            top: -15px;
            left: calc(100% + 0px);
  
            .subMenu {
              li {
                position: relative;
  
                &:first-child {
                  right: -75%;
                }
  
                &:nth-child(2) {
                  right: -50%;
                }
  
                &:nth-child(3) {
                  right: -25%;
                }
              }
            }
          }
        }
      }
  
      .subMenuDot1,
      .subMenuDot2 {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        position: absolute;
  
        &.active {
          border: 2px solid #fff;
          background: #F36A24;
  
          .menuItems {
            display: flex;
            align-items: center;
  
            .menuItemIcon {
              background: linear-gradient(180deg, #F36A24 0%, #C74300 100%);
              box-shadow: 0px 0px 12px 0px rgba(255, 173, 132, 0.30);
              border-radius: 50%;
              border-top: 1.048px solid rgba(255, 255, 255, 0.46);
            }
          }
        }
      }
    }
  
    .popup {
      background: url('../assets/roundImg.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 530px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('./navGradient.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
      }
  
      @media screen and (min-width:1699px) {
        background-size: 562px;
      }
      @media screen and (max-width:1600px) {
        min-height: 0px !important;
        height: calc(100vh - 70.25px) !important;
      }
      @media screen and (max-width:1550px) {
        background-size: 444px;
      }
  
      @media screen and (max-width:992px) {
  
        height: calc(100vh - 70.25px) !important;
        min-height: 800px !important;
  
      }
      @media only screen and (min-width: 1440px) and (max-width: 1550px){
        background-size: 445px;
      }
      @media screen and (max-width:1220px) {
        background-position: center left -265px;
      }
  
      @media screen and (max-width:767px) {
        background: none;
      }
      
    }
  
  }
  