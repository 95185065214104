.custom-container {
    max-width: 1520px !important;
    @media only screen and (min-width: 1440px) and (max-width: 1550px)
    {
        max-width: 1422px !important;
        padding-left: 12px;
        padding-right: 12px;
    }
}

// footer starts here
.footer {
    display: block;

    @media only screen and (max-width: 575px) {
        display: none;
    }
    
    .footer-arrow-img {
        border-radius: 62px;
        border: 1px solid #FFF;
        padding: 20px 42px 20px 42px;
        cursor: pointer;
        position: relative;
        &.absolute{
            position: absolute;
            z-index: 3;
        }

        @media only screen and (max-width: 576px) {
            width: 100%;
            padding: 20px 20px;
            justify-content: space-around;
        }

        p {
            font-weight: bold;
            color: white;
            margin: 0;

            @media only screen and (max-width: 400px) {
                font-size: 12px;
            }

        }
    }

    .footer-line {
        background-color: #FFF;
        height: 1px;
        width: calc(100% - 391px);

        @media only screen and (max-width: 576px) {
            display: none;
        }
    }

    .list {
        p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            a{
                transition: 0.3s ease;
                &:hover{
                    color: #F36A24 !important;
                }
            }
        }

        .footer-address {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
        }

        ul {
            list-style: none;

            @media only screen and (max-width: 1200px) {
                padding-left: 0px;
            }

            li {

                margin: 20px 0px;
                cursor: pointer;

                a {
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 25px;
                    text-decoration: none;
                    transition: 0.3s ease;

                    &:hover {
                        color: #F36A24 !important;
                    }
                }
            }
        }
    }
    .footerLogo{
        max-width: 203px;
        svg{
            max-width: 100%;
        }
    }
}

.ForAlign{
    @media screen and (max-width:767px) {
        justify-content: space-around;
        .list{
            &.last{
                text-align: center;
            }
        }
    }
}

.footerSocialLinks{
    border-radius: 64px;
    border: 0.5px solid #FFF;
    background: rgba(255, 255, 255, 0.10);
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;        
}

.copyright-row {
    margin-top: 100px;
    display: block;

    @media only screen and (max-width: 575px) {
        display: none;
    }

    @media only screen and (max-width: 576px) {
        margin-top: 20px;
    }

    .copyright-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        @media only screen and (max-width: 830px) {
            flex-direction: column-reverse;
            text-align: center;
        }




        .copyrightLeft {
            display: flex;
            gap: 20px;
            align-items: center;

            p {
                color: #FFF;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0;
                padding: 0;
                transition: 0.3s ease;

                &:hover {
                    color: #F36A24 !important;
                }
            }

            @media only screen and (max-width: 576px) {
                margin-top: 20px;
            }
        }

        .corightRight {
            p {
                text-align: end;
                color: #FFF;
                text-align: right;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0;
                padding: 0;


                @media only screen and (max-width: 830px) {

                    text-align: center;
                }

            }
        }
    }
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.rotate {
    animation: rotateAnimation 5s linear infinite;
    @media only screen and (max-width: 575px) {
animation: none;
        
    }
    
}